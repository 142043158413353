const contentFilter = document.querySelectorAll(".content-filter");

contentFilter.forEach((filter) => {
	if (filter.classList.contains("credit-cards")) {
		const cards = filter.parentNode.querySelectorAll(".card-container.tablet .card");
		initCreditCards(filter, cards);
	}
	if (filter.classList.contains('team')) {
		const employees = filter.parentNode.querySelectorAll('.team-container .employee');
		initTeam(filter, employees);
	}
	if (filter.classList.contains('blog-filter')) {
		let articles = filter.parentNode.querySelectorAll('.article-card')
		initBlogFilter(filter, articles);
	}
});

function initCreditCards(filter, cards) {
	const stateFilter = filter.querySelector("#state-filter");
	const countyFilter = filter.querySelector("#county-filter");
	const stateDeclaration = filter.parentElement.querySelector(".card-container.tablet .state");
	const countyDeclaration = filter.parentElement.querySelector(".card-container.tablet .county");

	//cards.forEach((card) => {
	//	if (card.dataset.state) {
	//		if (!stateFilter.querySelector(`option[value='${card.dataset.state}']`)) {
	//			const el = document.createElement("option");
	//			el.value = card.dataset.state;
	//			el.innerText = card.dataset.state;
	//			stateFilter.appendChild(el);
	//		}
	//	}
	//});

	stateFilter.addEventListener("change", (state) => {
		//countyFilter.innerHTML =
		//	'<option value="" disabled selected>Select County...</option><option value="all">All</option>';

		if (state.target.value == "all") {
			stateDeclaration.classList.remove('visible');
			countyFilter.disabled = true;
			//countyFilter.innerHTML =
			//	'<option value="" disabled selected>Select County...</option><option value="all">All</option>';
			countyFilter.selectedIndex = 0;
			countyDeclaration.style.display = "none";
			cards.forEach((card) => {
				if (state.target.value == "all") {
					card.classList.remove("hidden");
				}
			});
		} else {
			const options = countyFilter.querySelectorAll('option');
			console.log(options);
			options.forEach(el => {
				el.style.display = 'none';
			})
			cards.forEach((card) => {
				if (card.dataset.county && card.dataset.state == state.target.value) {
					const option = countyFilter.querySelector(`option[value="${card.dataset.county}"]`);
					if (option) {
						option.style.display = "block";
					}
					//if (countyFilter.querySelector(`option[value="${card.dataset.county}"]`)) {
					//	const el = document.createElement("option");
					//	el.value = card.dataset.county;
					//	el.innerText = card.dataset.county;
					//	countyFilter.appendChild(el);
					//}
				}

				if (card.dataset.state !== state.target.value) {
					card.classList.add("hidden");
					setTimeout(() => {
						card.style.display == "none";
					}, 200);
				} else {
					card.style.display == "block";
					setTimeout(() => {
						card.classList.remove("hidden");
					}, 200);
				}
			});
			countyFilter.disabled = false;

			stateDeclaration.querySelector(
				"img"
			).src = `/Content/Images/state-${state.target.value
				.replace(" ", "-")}.svg`;
			stateDeclaration.querySelector(".state-name").innerText = state.target.value;
			stateDeclaration.classList.add("visible");
		}
	});
	countyFilter.addEventListener("change", (county) => {
		if (county.target.value == "all") {
			cards.forEach((card) => {
				if (card.dataset.state == stateFilter.options[stateFilter.selectedIndex].value) {
					card.classList.remove("hidden");
				}
			});
			countyDeclaration.classList.remove("visible");
		} else {
			countyDeclaration.querySelector(".name").innerText = county.target.options[county.target.selectedIndex].text;
			countyDeclaration.classList.add("visible");

			cards.forEach((card) => {
				if (
					card.dataset.county == county.target.value &&
					card.dataset.state == stateFilter.options[stateFilter.selectedIndex].value
				) {
					card.classList.remove("hidden");
				} else {
					card.classList.add("hidden");
				}
			});
		}
	});
}

function initBlogFilter(filter, articles, reset = false) {
	const serviceFilter = filter.querySelector('#service-filter');
	//const stateFilter = filter.querySelector('#state-filter');
	const topicFilter = filter.querySelector('#topic-filter');
	let count = 0;

	articles.forEach((emp) => {
		if (emp.dataset.service) {
			if (!serviceFilter.querySelector(`option[value='${emp.dataset.service}']`)) {
				const el = document.createElement("option");
				el.value = emp.dataset.service;
				el.innerText = emp.dataset.service;
				serviceFilter.appendChild(el);
			}
		}
		if (count == 6) {
			emp.classList.add('full-width');
			count = 0;
		} else {
			count++;
		}
		
	});

	serviceFilter.addEventListener('change', (service) => {
		articles = document.querySelectorAll('.articles .article-card')
		//stateFilter.innerHTML =
		//	'<option value="" disabled selected>Select a State...</option><option value="all">All</option>';
		//if (topicFilter) {
		//	topicFilter.innerHTML =
		//	'<option value="" disabled selected>Select a county...</option><option value="all">All</option>';
		//}
		let filterCount = 0;

		if (articles.length > 0) {
			articles[0].parentElement.classList.remove('visible');
		}
		articles.forEach(art => {
			art.classList.remove('hidden');
			art.classList.remove('full-width');
		})
		if (service.target.value == "all") {
			articles.forEach(emp => {
				emp.classList.remove('hidden');
				emp.classList.remove('full-width');
				if (filterCount == 6) {
					emp.classList.add('full-width');
					filterCount = 0;
				} else {
					filterCount++;
				}
				
			})
			//stateFilter.disabled = true;
			if (topicFilter) {
				topicFilter.disabled = true;
			}
			
		} else {
			articles.forEach(emp => {
				if (emp.dataset.cat.indexOf(service.target.value) !== -1) {
					emp.classList.remove('hidden')
					//if (reset) {
					//	if (!stateFilter.querySelector(`option[value=${emp.dataset.county}]`)) {
					//		const el = document.createElement("option");
					//		el.value = emp.dataset.county;
					//		el.innerText = emp.dataset.county;
					//		stateFilter.appendChild(el);
					//	}
					//}
					
					if (filterCount == 6) {
						emp.classList.add('full-width');
						filterCount = 0;
					} else {
						filterCount++;
					}
					
				} else {
					emp.classList.add('hidden')
					emp.classList.remove('full-width');
				}
			})
			//topicFilter.disabled = false;
		}
		setTimeout(() => {
			if (articles.length > 0) {
				articles[0].parentElement.classList.add('visible');
			}
		}, 200)
	});

	if (topicFilter) {
		topicFilter.addEventListener('change', (state) => {
		topicFilter.innerHTML =
			'<option value="" disabled selected>Select a county...</option><option value="all">All</option>';

		if (county.target.value == "all") {
			articles.forEach(emp => {
				if (emp.dataset.service == serviceFilter.options[serviceFilter.selectedIndex].value) {
					emp.classList.remove('hidden');
				}

			})
			topicFilter.disabled = true;
		} else {
			articles.forEach(emp => {
				if (emp.dataset.county == county.target.value && emp.dataset.service == serviceFilter.options[serviceFilter.selectedIndex].value) {
					emp.classList.remove('hidden')
					if (!topicFilter.querySelector(`option[value=${emp.dataset.county}]`)) {
						const el = document.createElement("option");
						el.value = emp.dataset.county;
						el.innerText = emp.dataset.county;
						topicFilter.appendChild(el);
					}
				} else {
					emp.classList.add('hidden')
				}
			})
			topicFilter.disabled = false;
		}
	})
	}
}

function initTeam(filter, employees) {
	const serviceFilter = filter.querySelector('#service-filter');
	//const stateFilter = filter.querySelector('#state-filter');
	const countyFilter = filter.querySelector('#county-filter');

	employees.forEach((emp) => {
		if (emp.dataset.service && serviceFilter) {
			const serviceCode = emp.dataset.service.replace("|", "");
			const filterExists = serviceFilter.querySelector(`option[value="${serviceCode}"]`)
			if (!filterExists) {
				const el = document.createElement("option");
				el.value = emp.dataset.service;
				el.innerText = emp.dataset.service;
				serviceFilter.appendChild(el);
			}
		}
	});
	if (countyFilter) {
		const counties = Array.from(countyFilter.querySelectorAll('option'));
		const removedEls = counties.splice(0, 2);
		const countyObjArray = [];
		counties.forEach(el => {
			const obj = {
				value: el.value,
				state: el.innerText.split(' - ')[1],
				name: el.innerText.split(' - ')[0]
			}
			countyObjArray.push(obj);
		})

		countyObjArray.sort(
			function (a, b) {
				if (a.state === b.state) {
					// Price is only important when cities are the same
					return b.name - a.name;
				}
				return a.state > b.state ? 1 : -1;
			});

		//countyObjArray.sort((a, b) => a.state > b.state ? 1 : -1);
		//countyObjArray.sort((a, b) => a.name > b.name ? 1 : -1);
		countyFilter.innerHTML = "";
		removedEls.forEach(x => {
			countyFilter.appendChild(x);
		})
		countyObjArray.forEach(x => {
			const newEl = document.createElement('option');
			newEl.value = x.value;
			newEl.innerText = x.name + " - " + x.state;
			countyFilter.appendChild(newEl);
		})
	}
	if (serviceFilter) {
		serviceFilter.addEventListener('change', (service) => {
			//stateFilter.innerHTML =
			//	'<option value="" disabled selected>Select a State...</option><option value="all">All</option>';
			countyFilter.innerHTML =
				'<option value="" disabled selected>Select a county...</option><option value="all">All</option>';

			if (service.target.value == "all") {
				employees.forEach(emp => {
					emp.classList.remove('hidden');
				})
				//stateFilter.disabled = true;
				countyFilter.disabled = true;
			} else {
				employees.forEach(emp => {
					if (emp.dataset.service.replace("|", "") == service.target.value) {
						emp.classList.remove('hidden')
						if (!stateFilter.querySelector(`option[value=${emp.dataset.county}]`)) {
							const el = document.createElement("option");
							el.value = emp.dataset.county;
							el.innerText = emp.dataset.county;
							stateFilter.appendChild(el);
						}
					} else {
						emp.classList.add('hidden')
					}
				})
				countyFilter.disabled = false;
			}
		});
	} else {
		countyFilter.disabled = false;
	}

	countyFilter.addEventListener('change', e => {
		if (e.target.value == "all") {
			employees.forEach(emp => {
				emp.classList.remove('hidden');
			})
		} else {
			employees.forEach(emp => {
				if (serviceFilter && emp.dataset.service == serviceFilter.options[serviceFilter.selectedIndex].value) {
					if (e.target.value == emp.dataset.county) {
						emp.classList.remove('hidden');
					} else {
						emp.classList.add('hidden')
					}
				} else {
					if (emp.dataset.county.indexOf(e.target.value) != -1) {
						emp.classList.remove('hidden');
					} else {
						emp.classList.add('hidden')
					}
				}
			})
			
			
		}
	})
}
