const locationSearchForm = document.querySelector('#find-branch-form');

if (locationSearchForm) {
    function initialize() {

        //Branch search
        /*var locSearch = document.querySelector(".js-branch-modal-search-form");*/
        document.addEventListener("submit", function (e) {
            if (e.target.matches("form#find-branch-form")) {
                e.preventDefault();
                var inp = document.querySelector("#addressInput");
                loadBranchAddress(encodeLocationHTML(inp.value));
            }
        }, false);

        function encodeLocationHTML(s) {
            return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
        }

        function loadBranchAddress(address) {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                'address': address
            }, async function (results, status) {
                console.log("geocoder created");
                if (status == google.maps.GeocoderStatus.OK) {
                    var coords = results[0].geometry.location;
                    console.log(coords);
                    await setDistances(coords.lat(), coords.lng());
                    await fetchAndSortDistances();
                }
                else {
                    console.log("Address Not Found");
                    const addressInput = document.querySelector('#addressInput');
                    addressInput.classList.add('not-found');
                    addressInput.value = "Address Not Found";
                }
            });

        }

        function setDistances(addressLat, addressLon) {
            //Get distance from address for each branch
            document.querySelectorAll(".js-branch-location, .js-atm-location").forEach(function (loc) {
                if (loc.getAttribute("data-latlon") != null && loc.getAttribute("data-latlon").indexOf(",") != -1) {
                    var sp = loc.getAttribute('data-latlon').split(',');
                    lat = Number(sp[0].trim());
                    lon = Number(sp[1].trim());
                    dist = distance(addressLat, addressLon, lat, lon);
                    loc.setAttribute('data-distance', dist);
                }
            });
        }

        function fetchAndSortDistances() {
            const branchLocations = document.querySelectorAll('.js-branch-location[data-distance]');
            const atmLocations = document.querySelectorAll('.js-atm-location[data-distance]');

            const branchArray = [...branchLocations];
            const atmArray = [...atmLocations];

            branchArray.sort(function (a, b) { return a.dataset.distance - b.dataset.distance });
            atmArray.sort(function (a, b) { return a.dataset.distance - b.dataset.distance });

            const resultsDiv = document.querySelector('.result-container');
            resultsDiv.innerHTML = "";

            let branchResult = document.createElement('div');
            branchResult.classList.add('location')
            branchResult.innerHTML = "";

            branchResult.innerHTML = `<h3 class="location-title">
                <span>Branch</span> - ${Math.floor(branchArray[0].dataset.distance)} miles away
            </h3>` + branchArray[0].innerHTML;

            let atmResult = document.createElement('div');
            atmResult.classList.add("location");
            atmResult.innerHTML = `<h3 class="location-title">
										<span>ATM</span> - ${Math.floor(atmArray[0].dataset.distance)} miles away
									</h3>
									<div
										class="location-image"
										style="
											background-image: ${atmArray[0].parentElement.querySelector(".location-image").style.backgroundImage.replace('url("', "url('").replace('");', "');")};
										"
									></div>
									<h4 class="f-h4 location-name">
										${atmArray[0].innerText}
									</h4>
									<div class="location-info">
										${atmArray[0].nextElementSibling.innerHTML}
									</div>

									<div class="actions">
										${atmArray[0].nextElementSibling.nextElementSibling.innerHTML}
									</div>`

            resultsDiv.append(branchResult);
            resultsDiv.append(atmResult);
            vSlideDown(resultsDiv.parentElement);
        };

    }
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAkUpfLRM4twBM56RpzRz8RGQLEQl9D4LY&libraries=geometry,places&callback=initialize';
    document.body.appendChild(script);

    function distance(lat1, lon1, lat2, lon2) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        } else {
            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            return dist;
        }
    }
    locationSearchForm.addEventListener('click', (e) => {
        if (locationSearchForm.querySelector('#addressInput').classList.contains('not-found')) {
            e.target.classList.remove('not-found');
            e.target.value = "";
        }
    })
}

//logic to make ATM singlue if there is just one.

document.addEventListener('DOMContentLoaded', () => {
    const locationsContainer = document.querySelectorAll('.locations-container .location');

    locationsContainer.forEach((el) => {
        const atms = el.querySelectorAll('.js-atm-location');

        if (atms.length == 1) {
            let title = el.querySelector('.location-title span');
            title.innerText = title.innerText.slice(0, -1);
        }
    })
})

//Other Offices Content Filter

const otherOffices = document.querySelector(".other-offices-area");

if (otherOffices) {
    const buttons = otherOffices.querySelectorAll(".service-filter button");

    buttons.forEach(button => {
        button.addEventListener('click', (e) => {
            const service = e.target.dataset.service;

            const locationsContainer = otherOffices.querySelector('.filter-locations');
            const locations = locationsContainer.querySelectorAll('.location-listing');
            locationsContainer.classList.add('hidden');
            if (service == "all") {
                locations.forEach(loc => {
                    loc.classList.remove('hidden')
                })
            } else {
                locations.forEach(loc => {
                    if (loc.querySelector("li[data-service='" + service + "']")) {
                        loc.classList.remove('hidden');
                    } else {
                        loc.classList.add('hidden')
                    }
                })
            }

            setTimeout(() => {
                locationsContainer.classList.remove('hidden');
            }, 200)
            
            
        })
    })
}