//Closest Branch logic
function distance(lat1, lon1, lat2, lon2) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        return dist;
    }
}

async function displayClosest() {

    if (document.querySelector('.js-closest-locations')) {
        let closestLocations = [];
        const destination = document.querySelector('.js-closest-locations');

        const allLocations = document.querySelectorAll('.js-location-data');

        if (getCookie('locationID')) {
            const locationCookie = getCookie("locationID");
            let cookieLatLon;
            let homeLocation;
            allLocations.forEach((element, id) => {
                if (element.dataset.locationid == locationCookie) {
                    //const el = Array.from(allLocations).splice(id, 1);
                    //closestLocations.unshift(el);
                    homeLocation = element;
                    cookieLatLon = homeLocation.dataset.latlon.split(',');
                } else {
                    closestLocations.push(allLocations[id])
                }
            })
            for (let x = 0; x < closestLocations.length; x++) {
                if (!closestLocations[x].querySelector(`[data-locationid="${locationCookie}"]`)) {
                    addElement(closestLocations[x], destination);
                }
            }
            let locations = [];
            document.querySelectorAll('.js-closest-locations > .js-location-data').forEach((el) => locations.push(el));
            let sortedLocations;
            locations = determineDistance(locations, cookieLatLon[0], cookieLatLon[1]);
            sortedLocations = sortList(locations);
            destination.innerHTML = '';
            sortedLocations.forEach((el, id) => {
                if (id < 2) {
                    addElement(el, destination);
                }
            })
            addElement(homeLocation, destination, true);

        } else {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(getLocationsByIP);
            }
        }

    }

}

document.addEventListener("click", function (e) {
    if (e.target.matches(".js-initial")) {
        var initial = document.querySelector(".initial");
        initial.style.display = "block";

        var pins = document.querySelector(".pins");
        pins.style.display = "none";
    }
    return;
});

function getLocationsByIP(position) {
    const destination = document.querySelector('.js-closest-locations');
    let allLocations = document.querySelectorAll('.js-location-data');
    var closestDiv = document.querySelector(".closestLocations");
    allLocations.forEach((el, x) => {
        addElement(el, destination);
    })
    let locations = [];
    document.querySelectorAll('.js-closest-locations > .js-location-data').forEach((el) => locations.push(el));
    let sortedLocations;
    /*await sortBranches(position.coords.latitude, position.coords.longitude, '.js-closest-locations > .js-location-data');*/
    /*console.log(locations);*/
    locations = determineDistance(locations, position.coords.latitude, position.coords.longitude);
    sortedLocations = sortList(locations);

    destination.innerHTML = '';
    sortedLocations.forEach((el, id) => {
        if (id < 3) {
            addElement(el, destination);
        }
    });
    vSlideDown(closestDiv);
}

function addElement(el, container, prepend = false) {
    let div = document.createElement("div");
    div.innerHTML = el.innerHTML;
    div.classList = el.classList;
    div.dataset.latlon = el.dataset.latlon;
    if (prepend) {
        container.prepend(div);
    } else {
        container.appendChild(div);
    }
}


function sortList(arrayToSort) {
    arrayToSort.
        sort(function (a, b) {
            if (Number(a.dataset.distance.replace(" Miles Away", "")) < Number(b.dataset.distance.replace(" Miles Away", ""))) {
                return -1;
            }
            else {
                return 1;
            }
        })
    return arrayToSort;
}

function determineDistance(nodeArray, addressLat, addressLon) {
    Array.from(nodeArray).forEach(function (loc) {
        if (loc.getAttribute("data-latlon") != null && loc.getAttribute("data-latlon").indexOf(",") != -1) {
            var sp = loc.getAttribute('data-latlon').split(',');
            lat = Number(sp[0].trim());
            lon = Number(sp[1].trim());
            dist = distance(addressLat, addressLon, lat, lon);
            loc.setAttribute('data-distance', dist);
            if (loc.querySelector('.js-distance')) {
                loc.querySelector(".js-distance").textContent = (Math.round(dist * 10) / 10) + " Miles Away";
            }

        }
    });
    return nodeArray;
}




/*** ALL LOCATIONS PAGE */
var stateFilter = document.querySelector(".js-state-filter");
if (stateFilter) {
    var states = document.querySelectorAll(".state-section");

    stateFilter.addEventListener("change", function (e) {
        if (e.target.value == "all") {
            states.forEach(function (it) {
                it.style.display = "block";
            });
        }
        else {
            states.forEach(function (it) {
                if (it.getAttribute("data-state") != e.target.value) {
                    it.style.display = "none";
                }
                else {
                    it.style.display = "block";
                }
            });

        }
    });

    if (window.location.hash) {
        const hash = window.location.hash.replaceAll("<", "").replaceAll(">", "").substring(1);

        stateFilter.querySelectorAll('option').forEach((el, index)=> {
            if (hash.toLowerCase() == el.text.toLowerCase()) {
                stateFilter.selectedIndex = index;
                stateFilter.dispatchEvent(new Event('change'));
            }
        })
    }
    
}
