//const tourMarkup = document.querySelector('.tour');
//const urlParams = window.location.search;

//if (tourMarkup) {
//    // if (!localStorage.getItem('tourDismissed') || urlParams.indexOf('tour') != -1) {
//    loadTour(tourMarkup);
//    // localStorage.setItem('tourDismissed', 'true');
//    // }
//}

//function loadSlide(current, slides, dots) {
//    const prevArrow = tourMarkup.querySelector('.prev-button');
//    const nextArrow = tourMarkup.querySelector('.next-button');
//    const tourArrow = tourMarkup.querySelector("#tour-arrow");

//    slides[0].parentNode.classList.remove('visible');
//    tourArrow.classList.add('hide');

//    slides.forEach(slide => {
//        if (slide.dataset.slide == current) {
//            slide.style.display = "grid";
//            slide.classList.add('active');
//        } else {
//            slide.style.display = "none";
//            slide.classList.remove('active');

//        }

//    })
//    dots.forEach(dot => {
//        if (dot.dataset.slide == current) {
//            dot.classList.add('active');
//        } else {
//            dot.classList.remove('active');
//        }
//    })
//    if (current == 0) {
//        prevArrow.disabled = true;
//    } else {
//        prevArrow.disabled = false;
//    }
//    if (current == (slides.length - 1)) {
//        nextArrow.disabled = true;
//    } else {
//        nextArrow.disabled = false;
//    }

//    if (current == 2) {
//        const iconBanner = document.querySelector('.icon-banner');
//        iconBanner.scrollIntoView({
//            behavior: "smooth",
//            block: "end"
//        });
//    } else if (current == 4) {
//        const photoSplit = document.querySelector('.photo-split');
//        photoSplit.scrollIntoView({
//            behavior: "smooth",
//            block: "end"
//        });
//    } else {
//        window.scroll({ top: 0, behavior: "smooth" });
//    }

//    setTimeout(() => {
//        slides[0].parentNode.classList.add('visible');
//        tourArrow.classList.remove('hide')
//    }, 200)
//}

//function loadTour(tour) {
//    tour.classList.add('visible');
//    const closeBtn = tour.querySelectorAll('.js-close-tour');
//    const slides = tour.querySelectorAll('.slide-content');
//    const dotNav = tour.querySelector('.dot-nav');
//    const dots = [];
//    const prevArrow = tourMarkup.querySelector('.prev-button');
//    const nextArrow = tourMarkup.querySelector('.next-button');

//    slides[0].parentNode.classList.add('visible');
//    nextArrow.remove();
//    slides.forEach((slide, index) => {
//        const newEl = document.createElement('button');
//        newEl.classList.add('dn-dot');
//        newEl.setAttribute('data-slide', index);
//        newEl.innerHTML = `<span class="visuallyhidden">${index}</span>`;
//        dotNav.appendChild(newEl);
//        dots.push(newEl);
//    })

//    dotNav.appendChild(nextArrow);

//    loadSlide(0, slides, dots);
//    closeBtn.forEach(btn => {
//        btn.addEventListener('click', () => {
//            tour.classList.remove('visible');
//        })
//    })

//    dots.forEach(dot => {
//        dot.addEventListener('click', (e) => {
//            loadSlide(e.target.dataset.slide, slides, dots)
//        })
//    })

//    prevArrow.addEventListener('click', () => {
//        const current = tourMarkup.querySelector('.dn-dot.active');
//        loadSlide((Number(current.dataset.slide) - 1), slides, dots);
//    })
//    nextArrow.addEventListener('click', () => {
//        const current = tourMarkup.querySelector('.dn-dot.active');
//        loadSlide((Number(current.dataset.slide) + 1), slides, dots);
//    })

//    //document.addEventListener('click', (e) => {
//    //    e.stopPropagation();
//    //    if (tourMarkup.classList.contains('visible') && e.target.classList.contains('pg-internal')) {
//    //        tour.classList.remove('visible');
//    //    }
//    //})
//    tour.querySelector('#take-tour').addEventListener('click', () => {
//        loadSlide(1, slides, dots);
//    })
//    tour.querySelector('#js-replay-tour').addEventListener('click', () => {
//        loadSlide(0, slides, dots);
//    })

//    window.scrollTo(0, 0);

//}

const tour = document.querySelector('.tour')

if (tour) {

    document.querySelector("body").addEventListener('click', (e) => {
        if (document.querySelector('body').classList.contains('tour-active') && !e.classList.contains('pop-window')) {
            document.querySelector('body').classList.remove('tour-active');
            $(".showOnTour").removeClass("showOnTour");
        }
    })
    document.addEventListener("DOMContentLoaded", () => {

        var currentSlide = 0;

        //let cook = localStorage.getItem('tourDismissed');

        if ($(window).width() > 1100) {
            document.querySelector('body').classList.add('showTour');
            //if (!cook) {
            initDotNav();

            let headerHeight = 0;

            if (document.querySelector(".alerts-holder")) {
                headerHeight += $('.alerts-holder').height();
            }
            if (document.querySelector(".fdic-banner")) {
                headerHeight += $(".fdic-banner").height();
            }
            if (document.querySelector(".headers")) {
                headerHeight += $('.headers').height();
            }

            $(".tour").css("top", headerHeight)

            $(".tour").show();
            window.scroll({ top: 0, behavior: "smooth" })
            loadTourSlide(0)
                //localStorage.setItem('tourDismissed', 'true');
            //}

        }

        function initDotNav() {
            const slides = tour.querySelectorAll('.tour-slide');
            const dotNav = tour.querySelectorAll('.dot-nav');

            slides.forEach((slide, index) => {
                const dotNav = slide.querySelector('.dot-nav');
                const nextArrow = slide.querySelector('.next-button');
                nextArrow.remove();
                for (let x = 0; x < slides.length; x++) {
                    const newEl = document.createElement('button');
                    newEl.classList.add('dn-dot');
                    newEl.setAttribute('data-slide', x);
                    newEl.innerHTML = `<span class="visuallyhidden">${x}</span>`;
                    dotNav.appendChild(newEl);
                }
              
                dotNav.appendChild(nextArrow);

            })
            
        }

        
        function loadTourSlide(spot) {
            const slides = tour.querySelectorAll('.tour-slide');
            const dots = tour.querySelectorAll('.dn-dot');
            const prevArrow = tour.querySelectorAll('.prev-button');
            const nextArrow = tour.querySelectorAll('.next-button');
             $(".tour-arrow").removeClass("hide");
            $(".tour-slide[data-slide='" + currentSlide + "']").fadeOut("fast", function () {
                document.querySelectorAll(".showOnTour").forEach((e) => {
                    e.classList.remove("showOnTour");
                }
                )
               
                $(this).removeClass("active");
                currentSlide = Number(spot);
                setPosition(currentSlide);
                $(".tour-slide[data-slide='" + currentSlide + "']").fadeIn("fast", function () {
                    $(this).addClass("active");
                    $(".tour-arrow").addClass('visible')
                    $("body").addClass("tour-active").attr("data-slide", currentSlide);
                });
            });
            dots.forEach(dot => {
                if (dot.dataset.slide == spot) {
                    dot.classList.add('active');
                } else {
                    dot.classList.remove('active');
                }
            })
            if (spot == 0) {
                prevArrow.forEach(arrow => {
                    arrow.disabled = true;
                })
                
            } else {
                prevArrow.forEach(arrow => {
                    arrow.disabled = false;
                })
            }
            if (spot == (slides.length - 1)) {
                nextArrow.forEach(arrow => {
                    arrow.disabled = true;
                })
            } else {
                nextArrow.forEach(arrow => {
                    arrow.disabled = false;
                })
            }
            setTimeout(() => {
                $(".tour-arrow").addClass('visible')
            }, 200)
        }

        function setPosition(spot) {
            console.log(spot);
            //document.querySelector('.headers').classList.remove('showOnTour');
            //document.querySelector('.headers').classList.remove('highlightNav');
            //tour.classList.remove('noBG');
            //body.classList.remove('nav-open');
            //document.querySelector('.headers .main-nav a[href="/personal"').parentNode.classList.remove('tour-open');
            const centered = (window.innerWidth - $(`.tour-slide[data-slide="${spot}"`).width()) / 2;

            let headerHeight = 0;

            if (document.querySelector(".alerts-holder")) {
                headerHeight += $('.alerts-holder').height();
            }
            else {
                headerHeight += 69;
            }
            if (document.querySelector(".fdic-banner")) {
                headerHeight += $(".fdic-banner").height();
            }
            if (document.querySelector(".headers")) {
                headerHeight += $('.headers').height();
            }

            switch (Number(spot)) {
                case 0:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    document.querySelector(".headers").classList.remove('highlightNav');
                    $(`.tour-slide[data-slide="${spot}"`).css("top", 0 + "px").css("left", centered);
                    break;
                case 1:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    //document.querySelector(".headers").classList.add('showOnTour');
                    document.querySelector(".headers").classList.add('highlightNav');
                    var width = $(`.tour-slide[data-slide="${spot}"`).width()
                    var posX = $(".headers .login-btn").offset().left + $(window)['scrollLeft']();
                    var posY = $('.headers .login-btn').offset().top - $(window)['scrollTop']();
                    $(`.tour-slide[data-slide="${spot}"`).css("top", posY + "px").css("left", (posX - width) + "px");
                    break;
                case 2:
                    document.querySelector('.icon-banner').scrollIntoView({
                        behavior: "smooth",
                        block: "end"
                    });
                    document.querySelector(".headers").classList.remove('highlightNav');
                    /*                    document.querySelector('.headers .loginBtn').classList.add('showOnTour');*/
                    var posX = $(".icon-banner").offset().left + $(window)['scrollLeft']();
                    var posY = $(window)['scrollTop']();
                    //$(`.tour-slide[data-slide="${spot}"`).css("top", posY + "px").css("left", posX + "px").css("height", "unset");
                    $(`.tour-slide[data-slide="${spot}"`).css("top", "calc(40vw + -415.08px)").css("left", "calc(50vw - 70%)").css("height", "unset");
                    break;
                case 3:
                    //banking center slide
                    document.querySelector(".headers").classList.add('highlightNav');
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    $(`.tour-slide[data-slide="${spot}"`).css("top", 75 + "px").css("left", centered);
                    break;
                case 4:
                    //connect slide
                    document.querySelector(".headers").classList.remove('highlightNav');
                    document.querySelector('.photo-split').scrollIntoView({
                        behavior: "smooth",
                        block: "end"
                    });
                    var posX = $(".photo-split").offset().left + $(window)['scrollLeft']();
                    var posY = $(window)['scrollTop']();
                    //$(`.tour-slide[data-slide="${spot}"`).css("top", posY + "px").css("left", centered);
                    $(`.tour-slide[data-slide="${spot}"`).css("top", "calc(41vw)").css("left", centered);
                    break;
                case 5:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    //document.querySelector(".headers").classList.add('showOnTour');
                    document.querySelector(".headers").classList.add('highlightNav');
                    var width = $(`.tour-slide[data-slide="${spot}"`).width()
                    var posX = $(".headers .login-btn").offset().left + $(window)['scrollLeft']();
                    var posY = $('.headers .login-btn').offset().top - $(window)['scrollTop']();
                    $(`.tour-slide[data-slide="${spot}"`).css("top", posY + "px").css("left", (posX - width) + "px");
                    break;
                case 6:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    //document.querySelector(".headers").classList.add('showOnTour');
                    document.querySelector(".headers").classList.add('highlightNav');
                    var width = $(`.tour-slide[data-slide="${spot}"`).width()
                    var posX = $(".headers .login-btn").offset().left + $(window)['scrollLeft']();
                    var posY = $('.headers .login-btn').offset().top - $(window)['scrollTop']();
                    $(`.tour-slide[data-slide="${spot}"`).css("top", posY + "px").css("left", (posX - width) + "px");
                    break;
                case 7:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    //document.querySelector(".headers").classList.add('showOnTour');
                    document.querySelector(".headers").classList.add('highlightNav');
                    var width = $(`.tour-slide[data-slide="${spot}"`).width()
                    var posX = $(".headers .login-btn").offset().left + $(window)['scrollLeft']();
                    var posY = $('.headers .login-btn').offset().top - $(window)['scrollTop']();
                    $(`.tour-slide[data-slide="${spot}"`).css("top", posY + "px").css("left", (posX - width) + "px");
                    break;
                case 8: 
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    //document.querySelector(".headers").classList.add('showOnTour');
                    document.querySelector(".headers").classList.add('highlightNav');
                    var width = $(`.tour-slide[data-slide="${spot}"`).width()
                    var posX = $(".headers .locations-btn").offset().left + $(window)['scrollLeft']();
                    var posY = $('.headers .locations-btn').offset().top - $(window)['scrollTop']();
                    $(`.tour-slide[data-slide="${spot}"`).css("top", posY + "px").css("left", (posX - width) + "px");
                    break;
                case 9:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    //document.querySelector(".headers").classList.add('showOnTour');
                    document.querySelector(".headers").classList.add('highlightNav');
                    var width = $(`.tour-slide[data-slide="${spot}"`).width()
                    var posX = $(".headers .search-btn").offset().left + $(window)['scrollLeft']();
                    var posY = $('.headers .search-btn').offset().top - $(window)['scrollTop']();
                    $(`.tour-slide[data-slide="${spot}"`).css("top", posY + "px").css("left", (posX - width) + "px");
                    break;
                case 10:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    document.querySelector(".headers").classList.remove('highlightNav');
                    $(`.tour-slide[data-slide="${spot}"`).css("top", 0 + "px").css("left", centered);
                    break;
                case 11:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    document.querySelector(".headers").classList.remove('highlightNav');
                    $(`.tour-slide[data-slide="${spot}"`).css("top", 0 + "px").css("left", centered);
                    break;
            }
        }

        $(window).on("scroll", function () {
            if (document.querySelector('body').classList.contains('tour-active')) {
                setPosition(currentSlide);
            }

        });
        $(window).on("resize", function () {
            if (document.querySelector('body').classList.contains('tour-active')) {
                setPosition(currentSlide);
            }
        });


        $(".dn-dot").on("click", function (e) {
            e.preventDefault();
            loadTourSlide($(this).attr("data-slide"));
        });

        $(".prev-button").on("click", function (e) {
            e.preventDefault();
            loadTourSlide(currentSlide - 1);
        });
        $(".next-button").on("click", function (e) {
            e.preventDefault();
            loadTourSlide(currentSlide + 1);
        });


        $("#take-tour").on("click", function (e) {
            e.preventDefault();
            loadTourSlide(1);
        });
        $(".js-start-tour,#js-replay-tour").on("click", function (e) {
            e.preventDefault();
            loadTourSlide(0);
        });
        $(".js-close-tour,.js-skip-tour").on("click", function (e) {
            e.preventDefault();
            $(".tour").hide();
            $("body").removeClass("tour-active").removeClass('nav-open').removeAttr("data-slide", currentSlide);
        });
        $(".tour").on("click", function (e) {
            $(this).hide();
            document.querySelector('.headers').classList.remove('showOnTour');
            document.querySelector('.headers').classList.remove('highlightNav');
            $("body").removeClass("tour-active").removeClass('nav-open').removeAttr("data-slide", currentSlide);
        });

        $(".tour-slide").on("click", function (e) {
            e.stopPropagation();
        });
    })

}