document.addEventListener("click", function (e) {
    if (e.target.matches(".js-blog-btn")) {
        var btns = document.querySelectorAll(".js-blog-btn");
        btns.forEach(function (it) {
            it.classList.remove("active");
        });
        e.target.classList.add("active");

        filterBlog(e.target.getAttribute("data-cat"));
    }
    else if (e.target.matches(".js-blog-filter")) {
        document.querySelector(".filter-categories").classList.toggle("open");
    }
});

function filterBlog(cat) {
    var arts = document.querySelectorAll(".blog-articles .article-card");

    arts.forEach(function (it) {
        if (cat=="all" || it.getAttribute("data-cat").indexOf(cat) != -1) {
            it.style.display = "block";
        }
        else {
            it.style.display = "none";
        }
    });
}


var blogForm = document.querySelector("#blog-search-form");

if (blogForm) {
    blogForm.addEventListener("submit", function (e) {
        e.preventDefault();
        var inp = document.querySelector(".js-blog-search");
        var data = { Query: inp.value };
        fetch('/Blog/BlogSearch', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
        .then((response) => {
            if (!response.ok) {
                console.log('Network response was not ok');
                throw new Error('Network response was not ok');
            }
            //var arts = document.querySelector(".js-articles");

            //arts.innerHTML = data;

            return response.text();
        })
        .then((data) => {
            var arts = document.querySelector(".js-articles");

            arts.innerHTML = data;
            //const contentFilter = document.querySelectorAll(".content-filter");
            //const articles = document.querySelectorAll('.articles .article-card');
            //initBlogFilter(contentFilter, articles, true);
            document.querySelector('#service-filter').selectedIndex = 0;
        })
        .catch((error) => {
            console.error('Failed to fetch page: ', error)
        });
    });
}